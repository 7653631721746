'use client';

import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';

import { ErrorPage } from '@/app/system/ErrorPage';

type GlobalErrorProps = {
  error: Error & { digest?: string };
};
export default function GlobalError({ error }: GlobalErrorProps) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorPage />
      </body>
    </html>
  );
}
